import React from 'react';
import ReactDOM from 'react-dom';
import { ToastDismissReason } from '@gs-ux-uitoolkit-common/toast';
import { Deferred } from '@gs-ux-uitoolkit-common/core';
import { ToastImpl } from '../toast-impl';
import { domReady } from './dom-ready';
import { ToastConfig } from './toast-config';

let toastIdCounter = -1;
let currentlyDisplayedToast: ToastRef | undefined;
const waitingQueue: ToastRef[] = [];

// Function to create a container element for the Toast once the DOM is ready
const getContainer = new Promise<HTMLElement>(async resolve => {
    await domReady();
    const domEl = document.createElement('div');
    domEl.setAttribute('data-gs-uitk-component', 'toast-container');
    document.body.appendChild(domEl);
    resolve(domEl);
});

export const toastService = {
    /**
     * Shows a Toast, and returns its `ToastRef` instance.
     */
    show(config: ToastConfig): ToastRef {
        return new ToastRef(config);
    },

    /**
     * Dismisses all of the currently-displayed Toasts. The Promise is resolved
     * when the "hide" animation for all Toasts are complete.
     */
    async dismissAll(): Promise<void> {
        if (currentlyDisplayedToast) {
            // In each iteration dismiss function is called and it removes the toast from waitingQueue.
            // When using normal (not reversed) loop, the index indicates the wrong toast from the array.
            const length = waitingQueue.length - 1;
            for (let i = length; i >= 0; i--) {
                waitingQueue[i].dismiss();
            }
            waitingQueue.length = 0;

            await currentlyDisplayedToast.dismiss();
        }
    },
};

export type ToastService = typeof toastService;

/**
 * Class which represents a reference to a Toast instance.
 */
export class ToastRef {
    /**
     * Appends the given `toastRef` to the "show queue", or immediately displays the
     * Toast if there is no other Toast currently being displayed.
     *
     * This is to implement our "one Toast at a time" queue.
     */
    private static appendToShowQueue(toastRef: ToastRef) {
        if (!currentlyDisplayedToast) {
            // No Toast currently displayed, display it immediately
            currentlyDisplayedToast = toastRef;
            toastRef.render({ visible: true });
        } else {
            waitingQueue.push(toastRef);
        }
    }

    /**
     * A unique ID for the Toast instance
     */
    public readonly id = `gs-uitk-toast-${++toastIdCounter}`;

    /**
     * Whether the Toast is actually visible or not (it will be false as long
     * as it is queued).
     */
    private visible = false;

    /**
     * Set to true when the Toast is dismissed.
     */
    private dismissed = false;

    private readonly onDismissDeferred = new Deferred<ToastDismissReason>();
    private readonly onShowDeferred = new Deferred<void>();
    private readonly onHideDeferred = new Deferred<void>();

    constructor(private readonly config: ToastConfig) {
        ToastRef.appendToShowQueue(this);
    }

    /**
     * Retrieves the ToastConfig object for the Toast
     */
    public getConfig(): ToastConfig {
        return this.config;
    }

    /**
     * Private helper method which actually performs the displaying of the Toast.
     */
    private async render({ visible = true }: { visible: boolean }) {
        this.visible = visible;

        const containerEl = await getContainer;
        const config = this.config;
        ReactDOM.render(
            <ToastImpl
                key={this.id}
                className={config.className}
                autoDismiss={config.autoDismiss}
                status={config.status}
                visible={visible}
                dismissButton={config.dismissButton}
                onDismiss={this.handleDismiss}
                onHide={this.handleHide}
                onShow={this.handleShow}
                placement={config.placement}
            >
                {config.message}
            </ToastImpl>,
            containerEl
        );
    }

    /**
     * A Promise which will be resolved when the Toast has been dismissed,
     * either when:
     *
     * - The user clicks the dismiss ('x') button
     * - The Toast has been  automatically dismissed via the {@link #autoDismiss}
     *   timer, or
     * - Has been dismissed programatically by the application
     */
    public get onDismiss() {
        return this.onDismissDeferred.promise;
    }

    /**
     * A Promise which will be resolved when the Toast has been fully shown
     * (i.e. it's "show" animation has completed)
     */
    public get onShow() {
        return this.onShowDeferred.promise;
    }

    /**
     * A Promise which will be resolved when the Toast has been fully hidden
     * (i.e. it's "hide" animation has completed)
     */
    public get onHide() {
        return this.onHideDeferred.promise;
    }

    /**
     * Dismisses the Toast. The Promise is resolved when the "hide" animation is
     * complete.
     */
    public async dismiss(): Promise<void> {
        if (!this.dismissed) {
            this.handleDismiss('application');
        }

        // If this Toast was never shown, its onHide promise will never
        // resolve. Resolve it now to allow onHide handlers to trigger
        if (currentlyDisplayedToast !== this) {
            const { onHide } = this.config;
            if (onHide) onHide();
            this.onHideDeferred.resolve();
        }

        return this.onHide;
    }

    /**
     * Determines if the Toast is currently visible. It will not be visible if
     * it has been dismissed, or it is still waiting in the queue.
     */
    public isVisible() {
        return this.visible;
    }

    /**
     * Determines if the Toast has been dismissed or not.
     */
    public isDismissed() {
        return this.dismissed;
    }

    /**
     * Handles when the Toast has been dismissed by resolving the {@link #onDismiss}
     * promise, hiding the Toast, and calling the original onDismiss callback.
     */
    private handleDismiss = (reason: ToastDismissReason) => {
        this.dismissed = true;

        // If this Toast was made visible, hide it. If it was never made visible,
        // there is nothing to do
        if (this.visible) {
            this.render({ visible: false }); // hide the Toast
        }

        // If the Toast is in the queue to be shown, remove it now
        const idx = waitingQueue.indexOf(this);
        if (idx !== -1) {
            waitingQueue.splice(idx, 1);
        }

        const { onDismiss } = this.config;
        if (onDismiss) onDismiss(reason);

        this.onDismissDeferred.resolve(reason);
    };

    /**
     * Handles when the Toast has been fully shown by resolving the {@link #onShow}
     * promise, and calling the original onShow callback.
     */
    private handleShow = () => {
        const { onShow } = this.config;
        if (onShow) onShow();

        this.onShowDeferred.resolve();
    };

    /**
     * Handles when the Toast has been fully hidden by resolving the {@link #onHide}
     * promise, calling the original onHide callback, and either showing the next
     * Toast in the queue (if there is one), or completely destroying the ToastImpl
     * instance.
     */
    private handleHide = async () => {
        const { onHide } = this.config;
        if (onHide) onHide();

        if (waitingQueue.length > 0) {
            const nextToast = waitingQueue.shift()!;
            currentlyDisplayedToast = nextToast;

            nextToast.render({ visible: true });
        } else {
            // No more Toast! Empty the containerEl
            currentlyDisplayedToast = undefined;

            const containerEl = await getContainer;
            ReactDOM.render(<></>, containerEl); // destroy the Toast
        }

        this.onHideDeferred.resolve();
    };
}
