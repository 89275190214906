/**
 * Only used internally to normalize the select configuration.
 *  Helps us keep our code cleaner by removing the `undefined` check
 *  from various places.
 */
export interface NormalizedSelectConfig extends SelectConfig {
    disabled: boolean;
    searchEnabled: boolean;
}

/**
 * Main props for the <Select /> component.
 * Used to customize the behavior of the select component.
 */
export interface SelectConfig extends SelectCommonConfig {
    /**
     * Determines which option is pre-selected upon instantiation of the component.
     *
     * Use this prop if you would like the Select component to control the value - i.e. the value
     * is changed automatically once a user has selected a new value ("uncontrolled mode").
     *
     * If you would like to use the component in a "controlled" fashion where the value
     * that is selected always match the value in the given input prop, use the
     * {@link #value} prop instead.
     */
    initialValue?: null | string;

    /**
     * Whether the search input is displayed
     */
    searchEnabled?: boolean;

    /**
     * Determines which options are selected on the component. This is a "controlled" prop
     * where component will always reflect the selected value specified as a string.
     *
     * This prop is useful when using a state management solution like Redux (for instance), where you
     * always want the component to reflect the state of the store.
     *
     * In order to have the Select component reflect when a user selects a new value,
     * you *must* add an {@link #onChange} callback and provide a new string to this prop.
     *
     * Example:
     *
     *     render() {
     *         return <Select
     *             value={this.state.value}
     *             onChange={this.onChange} />;
     *     }
     *
     *     onChange = ({newValue}) => {
     *         this.setState({ value: newValue });
     *     };
     *
     * (Although, you will likely dispatch an action to the store in the `onChange` handler here.)
     *
     * Note: If you would like to simply specify a default value when the component is instantiated
     * and allow the Select component itself to update when users
     * select a new value, use {@link #initialValue} instead. If both {@link #initialValue} and
     * this prop are specified, this prop takes precedence.
     */
    value?: string | null;

    /**
     * Event emitted when a new option is selected by the user, or the selected option is removed.
     */
    onChange?: (evt: SelectChangeEvent) => void;
}

/**
 * Common props between the <Select /> and </SelectMultiple /> components.
 * Used to customize the behavior of the components.
 */
export interface SelectCommonConfig {
    /**
     * Whether the component is disabled.
     */
    disabled?: boolean;

    /**
     * Whether options should be filtered by input characters or not.
     * If false, the search event will still emit, but options will not be filtered.
     *
     * This can be set to `false`, for example, if you wish to filter the options outside of the
     * Select component, and pass the new options back into the select component.
     */
    filterOptionsOnSearch?: SelectFilterOptions | boolean;

    /**
     * The text shown when the select box search has no results as a result of filtering the options.
     *
     * Only displayed while {@link #filterOptionsOnSearch} is `true`
     */
    noResultsContent?: string;

    /**
     * The text that is shown when a user has selected all the possible options or no options are loaded.
     */
    noOptionsContent?: string;

    /**
     * The options to display in the dropdown.
     *
     * Example:
     *
     *     [
     *         { label: 'Option 1', value: 'option-1' },
     *         { label: 'Option 2', value: 'option-2' }
     *     ]
     */
    options?: SelectOption[];

    /**
     * Placeholder content for the select box when no selections have been made.
     */
    placeholder?: string;

    /**
     * Whether the remove button should be rendered on the component.
     */
    removeButton?: boolean;

    /**
     * The HTML to render for any given dropdown option.
     *
     * This can be used if you wish to customize the HTML content for each dropdown option.
     */
    optionRenderer?: (data: OptionRendererData) => string | HTMLElement;

    /**
     * The HTML to render for any given selected option.
     *
     * This can be used if you wish to customize the HTML content for the selected options.
     */
    selectedOptionRenderer?: (data: OptionRendererData) => string | HTMLElement;

    /**
     * Event emitted when the Select box's dropdown is closed (hidden).
     */
    onDropdownHide?: () => void;

    /**
     * Event emitted when the Select box's dropdown is displayed (shown).
     */
    onDropdownShow?: () => void;

    /**
     * Event emitted when a user types a character into the search box.
     */
    onSearch?: (evt: SelectSearchEvent) => void;
}

/**
 * Represents a single option in the dropdown.
 */
export type SelectOption = SelectOptionLeaf | SelectOptionGroup;

/**
 * Represents a single option in the dropdown.
 */
export interface SelectOptionLeaf {
    /**
     * Specifies whether the option is disabled. If the option is disabled, the user will not be
     * able to select it.
     *
     * Defaults to `false`
     */
    disabled?: boolean;

    /**
     * Label to display for the option in the dropdown.
     */
    label: string;

    /**
     * The underlying value for the given option.
     */
    value: string;

    /**
     * Object containing custom information that can be stored in each option.
     */
    customProperties?: Record<string, any>;
}

/**
 * Represents an option group or a single option in the dropdown.
 */
export interface SelectOptionGroup {
    /**
     * Label to display for the option group in the dropdown.
     */
    label: string;

    /**
     * An array of child options. Using this property turns the SelectOption into an Option Group.
     */
    options: SelectOptionLeaf[];
}

/**
 * Determines if the given `option` is a `SelectOptionGroup` (as opposed to a `SelectOptionLeaf`)
 */
export function isSelectOptionGroup(option: SelectOption): option is SelectOptionGroup {
    return !!(option as SelectOptionGroup).options;
}

/**
 * Props used to customize filter capabilities
 */
export interface SelectFilterOptions {
    /**
     * Minimum number of characters to type before a search is executed.
     *
     * Defaults to `1`
     */
    minChars?: number;

    /**
     * Maximum number of options to display on any given search.
     *
     * Defaults to `-1` for no limit
     */
    maxSearchResults?: number;
}

export interface OptionRendererData extends SelectOptionLeaf {
    /**
     * Signifies if the current option is selected.
     */
    selected: boolean;
}

/**
 * Represents the event emitted for the 'onSearch' callback.
 * Triggered each time a user types a character into the select component.
 */
export interface SelectSearchEvent {
    /**
     * The character(s) being searched.
     */
    searchValue: string;

    /**
     * The number of options being returned from the given search.
     */
    resultsCount: number;
}

/**
 * Represents the event emitted for the 'onChange' callback.
 * Triggered each time a user add/removes an option
 */
export interface SelectChangeEvent {
    /**
     * Currently selected option;
     */
    selectedOption: SelectOptionLeaf | null;

    /**
     * Currently selected value;
     */
    selectedValue: string | null;
}

/**
 * Represents the object of a choices.js selected option.
 * Used since choices.js does not expose that interface.
 */
export interface ChoicesSelectedOption {
    active: boolean;
    choiceId: number;
    highlighted: boolean;
    id: number;
    label: string;
    value: string;
    customProperties?: Record<string, any>;
}

/**
 * classes used for the single select component
 */
export const selectClassNames = {
    button: 'gs-uitk-select__button',
    containerInner: 'gs-uitk-select__inner',
    containerOuter: 'gs-uitk-select',
    group: 'gs-uitk-select__group',
    groupHeading: 'gs-uitk-select__heading',
    input: 'gs-uitk-select__input',
    inputCloned: 'gs-uitk-select__input--cloned',
    item: 'gs-uitk-select__item',
    itemDisabled: 'gs-uitk-select__item--disabled',
    itemOption: 'gs-uitk-select__item--choice',
    itemSelectable: 'gs-uitk-select__item--selectable',
    list: 'gs-uitk-select__list',
    listDropdown: 'gs-uitk-select__list-dropdown',
    listItems: 'gs-uitk-select__list--multiple',
    listSingle: 'gs-uitk-select__list--single',
    placeholder: 'gs-uitk-select__placeholder',
    selectedState: 'gs-uitk-select-highlighted',
};
