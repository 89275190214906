import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { ToastDismissReason } from '@gs-ux-uitoolkit-common/toast';
import { Alert } from '@gs-ux-uitoolkit-react/core';
import { ToastProps } from './toast-props';
import cx from 'classnames';

/**
 * Private implementation component for Toast. This is NOT to be called by
 * developers using the toolkit, but only by the ToastService. We do not expose
 * this in index.ts
 */
export const ToastImpl: FunctionComponent<ToastProps> = React.memo(props => {
    const {
        status = 'none',
        children,
        className,
        dismissButton = true,
        visible,
        autoDismiss,
        onDismiss = () => {},
        onShow = () => {},
        onHide = () => {},
        placement = 'bottom-left',
    } = props;
    const [visibleState, setVisibleState] = useState(true);
    const timerIdRef = useRef(-1);

    useEffect(() => {
        if (visibleState && autoDismiss) dimissLater();

        // Callbacks are fired when a toast is either shown or hidden.
        if (visible !== undefined) {
            setTimeout(visible ? onShow : onHide, 250); // for now, hardcode that the Fade animation is 250ms long
        } else {
            // Uncontrolled mode
            setTimeout(visibleState ? onShow : onHide, 250); // for now, hardcode that the Fade animation is 250ms long
        }
    });

    const doDismiss = (reason: ToastDismissReason) => {
        if (visible === undefined) {
            // uncontrolled mode
            setVisibleState(false);
        }
        resetTimeout();
        onDismiss(reason);
    };

    const resetTimeout = () => {
        clearTimeout(timerIdRef.current);
        timerIdRef.current = -1;
    };

    const dimissLater = () => {
        resetTimeout();
        timerIdRef.current = window.setTimeout(() => {
            doDismiss('timer');
        }, autoDismiss);
    };

    // Clean up autoDismiss timer if the component is destroyed
    useEffect(() => {
        return () => resetTimeout();
    });

    return (
        <Alert
            status={status}
            isOpen={visible != undefined ? visible : visibleState}
            toggle={!dismissButton ? undefined : () => doDismiss('user')}
            className={cx(
                'gs-uitk-toast',
                `gs-uitk-toast-placement-${placement}`,
                { 'toast-fixed': !visible || !visibleState },
                className
            )}
            data-gs-uitk-component="toast"
        >
            <div data-cy="gs-toast__message">{children}</div>
        </Alert>
    );
});
